import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Om oss" />
    <h1>Om oss</h1>
    <p>...</p>
  </Layout>
)

export default SecondPage
